<div class="login-container bg-gradient">
  <div class="login-box mat-elevation-z8">
    <h1>
      <span class="txt-primary">Log</span>
      <span class="txt-accent">In</span>
    </h1>
    <form [formGroup]="loginForm" [style.opacity]="isLoading ? '0.4' : '1'">
      <mat-form-field appearance="fill">
        <mat-label>Email : </mat-label>
        <mat-icon matPrefix>mail</mat-icon>
        <input
          type="email"
          matInput
          formControlName="email"
          placeholder="email"
          required
        />
        <mat-error>
          {{ loginForm.controls.email.errors | validateError : "Email " }}
        </mat-error>
      </mat-form-field>
      <br />
      <mat-form-field appearance="fill">
        <mat-label>Password : </mat-label>
        <mat-icon matPrefix>lock_outline</mat-icon>
        <input
          [type]="hide ? 'password' : 'text'"
          matInput
          formControlName="password"
          placeholder="enter password"
          required
        />
        <mat-icon matSuffix (click)="hide = !hide">
          {{ hide ? "visibility" : "visibility_off" }}
        </mat-icon>
        <mat-error>
          {{ loginForm.controls.password.errors | validateError : "Password " }}
        </mat-error>
      </mat-form-field>
      <br />
      <!-- <button type="button" mat-button routerLink="{{forgotPasswordRoute.url}}" class="txt-accent">Forgot Password?</button> -->
      <button
        type="submit"
        mat-raised-button
        color="primary"
        (click)="login()"
        [disabled]="loginForm.invalid"
      >
        Login
      </button>
    </form>

    <div class="loader" *ngIf="isLoading">
      <mat-spinner color="accent" [strokeWidth]="5"></mat-spinner>
    </div>
  </div>
</div>
