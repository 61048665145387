<mat-drawer-container>
  <mat-drawer #drawer [mode]="mode" [opened]="opened" class="drawer bg-primary">
    <div class="logo-box bg-primary">
      <img src="assets/images/logo.svg" (click)="onClickRoomzLogo()" />
    </div>
    <!-- <hr> -->
    <div class="menu bg-primary">
      <mat-list>
        <mat-list-item
          class="menu-item"
          routerLink="{{ dashboardRoute.url }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <div mat-line>Dashboard</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>
        <mat-list-item
          class="menu-item"
          routerLink="{{ userRoute.url }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>peoples</mat-icon>
          <div mat-line>User List</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>
        <mat-list-item
          class="menu-item"
          routerLink="{{ categoryRoute.url }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>category</mat-icon>
          <div mat-line>Category List</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>
        <mat-list-item
          class="menu-item"
          routerLink="{{ brandRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>branding_watermark</mat-icon>
          <div mat-line>Brand List</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>
        <mat-list-item
          class="menu-item"
          routerLink="{{ productRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>notes</mat-icon>
          <div mat-line>Listing Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ orderRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>local_mall</mat-icon>
          <div mat-line>Order Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ cmsRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>copy_all</mat-icon>
          <div mat-line>CMS Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ commisionRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>euro_symbol</mat-icon>
          <div mat-line>Price Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ reviewRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>reviews</mat-icon>
          <div mat-line>Review Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ reportRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>report</mat-icon>
          <div mat-line>Report Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ transactionRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>payments</mat-icon>
          <div mat-line>Transaction Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ associationRoute.path }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>corporate_fare</mat-icon>
          <div mat-line>Association Management</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLink="{{ changePasswordRoute.url }}"
          routerLinkActive="menu-item-active"
        >
          <mat-icon mat-list-icon>fingerprint</mat-icon>
          <div mat-line>Change Password</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>

        <mat-list-item
          class="menu-item"
          routerLinkActive="menu-item-active"
          (click)="logOut()"
        >
          <mat-icon mat-list-icon>logout</mat-icon>
          <div mat-line>Logout</div>
          <div class="active-circle bg-success"></div>
        </mat-list-item>
      </mat-list>
    </div>
  </mat-drawer>
  <mat-drawer-content class="drawer-content">
    <!-- <mat-toolbar color="primary" class="header">
      <button mat-icon-button *ngIf="mode==='over'" (click)="drawer.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h2>USER LIST</h2>
    </mat-toolbar> -->
    <div class="drawer-content-box">
      <router-outlet></router-outlet>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
